import logo from './logo.svg';
import './App.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { graphql } from 'relay-runtime'
import { useLazyLoadQuery } from 'react-relay'
import { useState } from 'react';
// Import the functions you need from the SDKs you need
/*
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCjzG8cP4Rg0mmZyUbVc6uza06ZJeWMqXI",

  authDomain: "barsysteem-5401b.firebaseapp.com",

  databaseURL: "https://barsysteem-5401b-default-rtdb.firebaseio.com",

  projectId: "barsysteem-5401b",

  storageBucket: "barsysteem-5401b.appspot.com",

  messagingSenderId: "491571646916",

  appId: "1:491571646916:web:b1d7aa9e331b945ce81491",

  measurementId: "G-Y7ELYKSW3M"

};


// Initialize Firebase

//const app = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);
*/
function App() {
  const [openDialog, setOpenDialog] = useState(false);

  function sendQuery() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <Button variant="contained" onClick={sendQuery}>Send query</Button>
        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby='alert-dialog-description'>
          <DialogTitle id="alert-dialog-title">
            {"Query Result:"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hello, World!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant='text'>Close</Button>
          </DialogActions>
        </Dialog>
      </header>
    </div>
  );
}

export default App;
